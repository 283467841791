<template>
  <!--    <vue-headful :title="title" />-->
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="8" md="12">
        <v-stepper id="data" v-model="e1" flat>
          <!-- pasos -->
          <v-stepper-header>
            <v-stepper-step click color="#7CC609" :complete="e1 > 0" step="1">
              <h2 class="font-weight-medium mb-1 color-stepper">paso 1</h2>
              <small> Elección de Dominios</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step color="#7CC609" :complete="e1 > 1" step="2">
              <h2 class="font-weight-medium mb-1 color-stepper">paso 2</h2>
              <small>Información de Personal</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step color="#7CC609" :complete="e1 > 2" step="3">
              <h2 class="font-weight-medium mb-1 color-stepper">paso 3</h2>
              <small>Pago</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step color="#7CC609" step="4">
              <h2 class="font-weight-medium mb-1 color-stepper">paso 4</h2>
              <small>Completado</small>
            </v-stepper-step>
          </v-stepper-header>
          <!-- fin numero pasos -->
          <v-stepper-items>
            <!-- contenido de los pasos 1 -->
            <v-stepper-content step="0">
              <v-card class="mb-8 mt-5 mx-auto" max-width="800" flat>
                <v-row no-gutters>
                  <v-col cols="12" lg="12" md="12">
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="(item, i) in detallePedidos"
                        :key="i"
                      >
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon
                              class="iconMobile"
                              color="#616161"
                              v-on:click="removeDomain(item.dominio)"
                            >
                              mdi-delete</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                        <v-row no-gutters>
                          <v-list-item-content>
                            <v-col cols="12" lg="8" md="12">
                              <v-list-item-title
                                disabled
                                class="text-precio2"
                                v-text="item.dominioFullName"
                              ></v-list-item-title>
                            </v-col>
                            <v-col cols="12" lg="4" md="12">
                              <v-list-item-title
                                class="text-precio3"
                                id="mobileprecio"
                                disabled
                                v-text="item.moneda + '' + item.precioReal.toFixed(2) +'/año'"
                              ></v-list-item-title>
                            </v-col>
                            <v-divider></v-divider>
                          </v-list-item-content>
                        </v-row>
                      </v-list-item>
                    </v-list-item-group>
                  </v-col>
                </v-row>
                <!-- <v-row no-gutters>
                      <v-col cols="12" lg="12" md="12">
                        <h2 class="display-2  mt-5 d-flex align-center flex-column">{{ titulo_otros }}</h2>
                        <BuscarDominioComponent
                          class=""
                          posicion="none"
                          @addToList="addToList"
                        ></BuscarDominioComponent>
                      </v-col>
                    </v-row> -->
              </v-card>
              <v-row no-gutters>
                <v-col class="d-flex align-end flex-column" md="12">
                  <v-btn
                    color="#17A2B8"
                    @click="
                      e1 = 1;
                      scrollTop();
                    "
                    class="white--text"
                    :disabled="stepOneDisable"
                  >
                    Continuar
                  </v-btn>
                  <small class="pl-5">paso 2: Información de Personal</small>
                </v-col>
              </v-row>
            </v-stepper-content>

            <!-- contenido de los pasos 2 -->
            <v-stepper-content step="1">
              <v-card class="mb-12 mx-auto" flat>
                <v-container>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-card
                        outlined
                        class="mx-auto"
                        width="800"
                        color="grey lighten-5"
                        elevation="2"
                      >
                        <v-card-title class="text-precio"
                          >Datos Personales</v-card-title
                        >
                        <v-divider></v-divider>
                        <v-row no-gutters>
                          <v-col cols="12" lg="6" class="py-0">
                            <v-text-field
                              class="pa-2"
                              label="Nombres"
                              placeholder="Nombres"
                              color="blue darken-2"
                              :rules="[(v) => !!v || 'Campo Obligatorio']"
                              required
                              v-model="informacionCompra.nombres"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6" class="py-0">
                            <v-text-field
                              class="pa-2"
                              label="Apellidos"
                              placeholder="Apellidos"
                              color="blue darken-2"
                              :rules="[(v) => !!v || 'Campo Obligatorio']"
                              required
                              v-model="informacionCompra.apellidos"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12" lg="6" class="py-0">
                            <v-text-field
                              class="pa-2"
                              color="blue darken-2"
                              :rules="[(v) => !!v || 'Campo Obligatorio']"
                              label="Identificación Ciudadana"
                              placeholder="Identificación Ciudadana"
                              required
                              v-model="informacionCompra.identificacion"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6" class="py-0">
                            <v-text-field
                              class="pa-2"
                              label="Teléfono"
                              placeholder="Teléfono"
                              color="blue darken-2"
                              :rules="[(v) => !!v || 'Campo Obligatorio']"
                              type="number"
                              required
                              v-model="informacionCompra.telefono"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" lg="12">
                      <v-card
                        outlined
                        class="mx-auto"
                        width="800"
                        color="grey lighten-5"
                        elevation="2"
                      >
                        <v-card-title class="text-precio"
                          >País y dirección de facturación</v-card-title
                        >
                        <v-divider></v-divider>
                        <v-row no-gutters>
                          <v-col cols="12" lg="6" class="py-0">
                            <v-select
                              light
                              class="pa-2 color-texto"
                              placeholder="Pais"
                              color="blue darken-2"
                              v-model="informacionCompra.isoPais"
                              label="Pais"
                              :items="paisList"
                              item-text="nombrePais"
                              item-value="codigoIso"
                              required
                              chips
                              :menu-props="{ top: true, offsetY: true }"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" lg="6" class="py-0">
                            <v-text-field
                              class="pa-4"
                              label="Estado"
                              placeholder="Estado"
                              color="blue darken-2"
                              :rules="[(v) => !!v || 'Campo Obligatorio']"
                              required
                              v-model="informacionCompra.estado"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12" lg="6" class="py-0">
                            <v-text-field
                              class="pa-2"
                              color="blue darken-2"
                              :rules="[(v) => !!v || 'Campo Obligatorio']"
                              label="Dirección Principal"
                              placeholder="Dirección Principal"
                              required
                              v-model="informacionCompra.direccion1"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6" class="py-0">
                            <v-text-field
                              class="pa-2"
                              color="blue darken-2"
                              :rules="[(v) => !!v || 'Campo Obligatorio']"
                              label="Dirección Secundaria"
                              placeholder="Dirección Secundaria"
                              required
                              v-model="informacionCompra.direccion2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6" class="py-0">
                            <v-text-field
                              class="pa-2"
                              label="Código Postal"
                              placeholder="Código Postal"
                              color="blue darken-2"
                              :rules="[(v) => !!v || 'Campo Obligatorio']"
                              required
                              v-model="informacionCompra.codigoPostal"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" lg="12">
                      <v-card
                        outlined
                        class="mx-auto"
                        width="800"
                        color="grey lighten-5"
                        elevation="2"
                      >
                        <v-card-title class="text-precio"
                          >Datos organizacionales</v-card-title
                        >
                        <v-divider></v-divider>
                        <v-row no-gutters>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              class="pa-2"
                              label="Organización"
                              placeholder="Organización"
                              color="blue darken-2"
                              :rules="[(v) => !!v || 'Campo Obligatorio']"
                              required
                              v-model="informacionCompra.empresa"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              class="pa-2"
                              label="ID Fiscal"
                              placeholder="ID Fiscal"
                              color="blue darken-2"
                              :rules="[(v) => !!v || 'Campo Obligatorio']"
                              required
                              v-model="informacionCompra.idFiscal"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-row class="" no-gutters>
                <v-col class="d-flex align-start flex-column" md="3">
                  <v-btn
                    color="#17A2B8"
                    class="white--text mr-13"
                    @click="
                      e1 = 0;
                      scrollTop();
                    "
                  >
                    Regresar
                  </v-btn>
                  <small>paso 1: Elección de Dominios</small>
                </v-col>

                <v-col class="d-flex align-end flex-column" md="9">
                  <v-btn
                    color="#17A2B8"
                    @click="
                      e1 = 2;
                      scrollTop();
                    "
                    class="white--text"
                    v-on:click="pasoDos"
                    :disabled="isStepTwoValid"
                  >
                    Continuar
                  </v-btn>
                  <small>paso 3: Pago</small>
                </v-col>
              </v-row>
            </v-stepper-content>
            <!-- contenido de los pasos 3 -->
            <v-stepper-content step="2">
              <v-card class="mb-12 mx-auto" flat>
                <v-container>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-card
                        class="mx-auto"
                        color="grey lighten-5"
                        elevation="1"
                      >
                        <v-card-title class="text-precio"
                          >Información de tarjeta
                        </v-card-title>
                        <!-- <v-card-subtitle class="tiposTarjeta">
                             <img src="../assets/img/visa.png" class="float-right ml-2 mt-n6" width="100" height="30" alt="">
                          </v-card-subtitle> -->
                        <v-form>
                          <v-container>
                            <v-divider class="tiposTarjeta"></v-divider>
                            <v-row no-gutters>
                              <v-col cols="12" lg="12">
                                <v-text-field
                                  class="pa-2 mt-5"
                                  label="Número de tarjeta"
                                  placeholder="Número de tarjeta"
                                  color="blue darken-2"
                                  :rules="[(v) => !!v || 'Campo Obligatorio']"
                                  v-model="tarjetaNumeracion"
                                  maxlength="16"
                                  required
                                >
                                  <template slot="append">
                                    <v-icon class="pa-1">fab fa-cc-visa</v-icon>
                                    <v-icon class="pa-1"
                                      >fab fa-cc-mastercard</v-icon
                                    >
                                  </template>
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" lg="6">
                                <v-select
                                  class="px-2"
                                  color="blue darken-2"
                                  v-model="tarjetaMesExpira"
                                  placeholder="Mes de vencimiento"
                                  label="Mes de vencimiento"
                                  :items="month"
                                  item-text="description"
                                  item-value="value"
                                  required
                                  chips
                                />
                              </v-col>
                              <v-col cols="12" lg="6">
                                <v-select
                                  class="px-2"
                                  color="blue darken-2"
                                  v-model="tarjetaAnioExpira"
                                  placeholder="Año de vencimiento"
                                  label="Año de vencimiento"
                                  :items="expireYear"
                                  item-text="description"
                                  item-value="value"
                                  required
                                  chips
                                />
                              </v-col>
                              <v-col cols="12" lg="6">
                                <v-text-field
                                  class="px-2"
                                  label="Código de seguridad"
                                  placeholder="Código de seguridad"
                                  color="blue darken-2"
                                  :rules="[(v) => !!v || 'Campo Obligatorio']"
                                  required
                                  v-model="tarjetaCodigo"
                                  type="password"
                                >
                                </v-text-field>
                              </v-col>

                              <!-- modal para proceder al pago -->
                              <v-col
                                class="d-flex align-center flex-column"
                                cols="12"
                                md="12"
                                lg="12"
                              >
                                <v-dialog
                                  v-model="dialog2"
                                  persistent
                                  max-width="450"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="white--text text-center large"
                                      tile
                                      color="#7CC609"
                                      upercase
                                      x-large
                                      :disabled="!checked"
                                      :disable="loading"
                                      value="enviar"
                                    >
                                      <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="primary"
                                      ></v-progress-circular>
                                      <span v-if="!loading"> proceder </span>
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title class="headline">
                                      Esta seguro de realizar el pago de su
                                      orden?
                                    </v-card-title>
                                    <v-card-text
                                      >Al presionar continuar se realizara un
                                      debito por un monto de
                                      <strong>${{ total }}</strong></v-card-text
                                    >
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="#D53737"
                                        text
                                        @click="dialog2 = false"
                                        class="d-flex  justify-start"
                                      >
                                        Cancelar
                                      </v-btn>
                                      <v-btn
                                        color="#7CC609"
                                        class="white--text"
                                        @click="proceder"
                                        v-show="dialog2"
                                      >
                                        Continuar
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>

                                <!-- <p v-else class="text-terminos ml-2">Debes marcar el checkbox para poder procesar el pago.</p> -->
                                <!-- modal -->
                                <template>
                                  <div class="text-center">
                                    <v-dialog
                                      persistent
                                      transition="dialog-top-transition"
                                      v-model="dialog"
                                      max-width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-col class="justify-space-around">
                                          <!-- <v-btn
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                          text
                                        >
                                        </v-btn> -->
                                          <v-checkbox v-model="checked">
                                            <template v-slot:label>
                                              <p class="text-terminos">
                                                Al presionar el botón usted
                                                acepta nuestros
                                                <span
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  class="terminos-bold "
                                                  >Términos y Condiciones de
                                                  pago</span
                                                >
                                              </p>
                                            </template>
                                          </v-checkbox>
                                        </v-col>
                                      </template>
                                      <v-card flat elevation="1">
                                        <v-card-title>
                                          <h2>
                                            Política de Términos y Condiciones
                                          </h2>
                                        </v-card-title>
                                        <v-card-text>
                                          <div v-html="parrafo"></div>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            color="uniColor"
                                            text
                                            v-on:click="dialog = false"
                                          >
                                            Aceptar
                                          </v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </v-dialog>
                                  </div>
                                </template>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-row no-gutters>
                <v-col class="d-flex align-start flex-column" md="3">
                  <v-btn
                    color="#17A2B8"
                    class="white--text mr-15"
                    @click="
                      e1 = 1;
                      scrollTop();
                    "
                  >
                    Regresar
                  </v-btn>
                  <small>paso 2: Información de Facturación</small>
                </v-col>
              </v-row>
            </v-stepper-content>
            <!-- contenido de los pasos 4 -->
            <v-stepper-content step="3">
              <v-card class="mb-12 mx-auto" color="grey lighten-5" flat>
                <h1 class="text-dominio text-center">
                  !Su pago ha sido realizado!
                </h1>
                <v-container>
                  <v-row justify="center">
                    <v-img
                      src="../assets/img/check.png"
                      max-height="150"
                      max-width="150"
                      class="text-center"
                    ></v-img>
                    <v-col cols="12">
                      <p class="text-terminos pa-2 text-center">
                        Número de Transaccion
                        <br />
                        {{ codigoResultado }}
                      </p>
                      <p class="text-terminos pa-2 text-center">
                        Número de Referencia
                        <br />
                        {{ codigoReferencia }}
                      </p>
                      <p class="text-terminos pa-2 text-center">
                        Hemos enviado el detalle de la compra a su cuenta de
                        correo, en caso de no haber recibido el mensaje
                        <span class="terminos-bold" v-on:click="reenviarcorreo"
                          >PRESIONE AQUI</span
                        >
                      </p>
                      <div class="text-center">
                        <v-btn
                          class="btn-reg ma-2 white--text"
                          tile
                          color="#E64A19"
                          upercase
                          x-large
                        >
                          <router-link :to="{ name: 'admin-dominios' }"
                            >IR A MIS DOMINIOS</router-link
                          >
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
          <!-- fin contenido de pasos -->
        </v-stepper>
      </v-col>
      <!-- fin pasos de compra -->
      <!-- Resumen de pedido desk -->
      <v-col
        class="justify-end ml-10   lighten-5"
        id="card-desk"
        cols="12"
        lg="3"
        md="12"
      >
        <div class="sticky">
          <v-card
            elevation="2"
            class="justify-space-around"
            color="titleCard"
            outlined
            min-height="80"
            dark
          >
            <v-card-title
              class="mt-2 color-text d-flex justify-space-around custom-transform-class"
              >Resumen de la orden</v-card-title
            >
          </v-card>
          <v-card id="card-desk" color="lime lighten-5" flat min-height="80">
            <v-row v-for="item in detallePedidos" :key="item.dominio">
              <v-col class="d-flex justify-end" cols="12" lg="6">
                <v-list-item-title class="ml-2 ">{{
                  item.dominioFullName
                }}</v-list-item-title>
              </v-col>
              <v-col class="ml-14" cols="12" lg="4">
                <v-list-item-title class=" d-flex mr-1 flex-row-reverse">{{
                  item.moneda + "" + item.precioReal.toFixed(2)
                }}</v-list-item-title>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col class="d-flex justify-end" cols="12" lg="6">
                <v-list-item-title class="ml-2 total">Total</v-list-item-title>
              </v-col>
              <v-col class="ml-14" cols="12" lg="4">
                <v-list-item-title class="total d-flex mr-1 flex-row-reverse"
                  >${{ total }}</v-list-item-title
                >
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <!-- reumen pedido para mobile -->
    <v-row>
      <v-col cols="12" lg="12">
        <v-card
          class="mx-auto  overflow-hidden sticky-bottom"
          dark
          max-height="40"
          color="titleCard"
          outlined
          elevation="1"
          flat
          id="card-mobile"
        >
          <v-row>
            <v-col class="ml-1 pa-5 align-start flex-column" md="1">
              <v-list-item-title class="total">Total</v-list-item-title>
            </v-col>
            <v-col class="d-flex pa-5 ml-16 align-end flex-column" md="1">
              <v-list-item-title class="total">${{ total }}</v-list-item-title>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/*import vueHeadful from "vue-headful";*/
import jwt_decode from "jwt-decode";
// import BuscarDominioComponent from "@/components/BuscarDominioComponent.vue";
import Vue from "vue";
import "vue-toast-notification/dist/theme-sugar.css";

export default {
  components: {
    // BuscarDominioComponent,
  },
  computed: {
    valordominio: function() {
      return (
        (this.$store.state.domainCurrency == "Dólar" ? "$" : "C$") +
        "" +
        this.$store.state.domainPrice.toFixed(2) +
        "/año"
      );
    },
    total: function() {
      if (this.detallePedidos.length > 0) {
        let sum = this.detallePedidos
          .map((o) => o.precioReal)
          .reduce((a, c) => {
            return a + c;
          });
        return sum.toFixed(2);
      }
      return 0;
    },
    isStepTwoValid: function() {
      if (
        this.informacionCompra.nombres === undefined ||
        this.informacionCompra.nombres.length === 0 ||
        this.informacionCompra.apellidos === undefined ||
        this.informacionCompra.apellidos.length === 0 ||
        this.informacionCompra.identificacion === undefined ||
        this.informacionCompra.identificacion.length === 0 ||
        this.informacionCompra.telefono === undefined ||
        this.informacionCompra.telefono.length === 0 ||
        this.informacionCompra.isoPais === undefined ||
        this.informacionCompra.isoPais.length === 0 ||
        this.informacionCompra.estado === undefined ||
        this.informacionCompra.estado.length === 0 ||
        this.informacionCompra.direccion1 === undefined ||
        this.informacionCompra.direccion1.length === 0 ||
        this.informacionCompra.direccion2 === undefined ||
        this.informacionCompra.direccion2.length === 0 ||
        this.informacionCompra.codigoPostal === undefined ||
        this.informacionCompra.codigoPostal.length === 0
      )
        return true;
      else return false;
    },
  },
  data: () => ({
    stepOneDisable: true,
    stepTwoDisable: true,
    stepThreeDisable: true,
    checked: false,
    dialog: false,
    dialog2: false,
    paisList: [],
    disponibilidadDominios: {},
    loading: false,
    pasoFinal: 0,
    codigoReferencia: 0,
    codigoResultado: "",
    month: [
      { description: "Enero", value: "1" },
      { description: "Febrero", value: "2" },
      { description: "Marzo", value: "3" },
      { description: "Abril", value: "4" },
      { description: "Mayo", value: "5" },
      { description: "Junio", value: "6" },
      { description: "Julio", value: "7" },
      { description: "Agosto", value: "8" },
      { description: "Septiembre", value: "9" },
      { description: "Octubre", value: "10" },
      { description: "Noviembre", value: "11" },
      { description: "Diciembre", value: "12" },
    ],
    expireYear: [],
    yearFormat: {
      description: 0,
      value: 0,
    },
    direccionIP: "",
    fingerPrint: "",
    informacionCompra: {
      nombres: "",
      apellidos: "",
      telefono: "",
      identificacion: "",
      isoPais: "",
      estado: "",
      codigoPostal: "",
      direccion1: "",
      direccion2: "",
      empresa: "",
      idFiscal: "",
    },
    tarjetaCodigo: "",
    tarjetaNumeracion: "",
    tarjetaMesExpira: "",
    tarjetaAnioExpira: "",
    Tarjeta: {
      Numeracion: "",
      mesExpira: "",
      anioExpira: "",
      codigo: "",
      nombre: "",
    },
    dominiosList: [],
    trackData: {
      trackName: "",
      trackSandbox: "",
    },
    detallePedidos: [],
    title: "Comprar Dominio",
    e1: 0,
    date: null,
    menu: false,
    titulo_otros: "¿Necesitas otro dominio?",
    subtitle2:
      "Te recomendamos proteger tu dominio en estas zonas, para evitar plagios a tu marca",
    titulo_busqueda: "Hemos encontrado estos dominios para usted",
    items: [
      { text: "dominio.tv.ni", icon: "mdi-check-circle" },
      { text: "dominio.ac.ni", icon: "mdi-check-circle" },
    ],
    parrafo: "",
    idPedido: 0,
    pedidosNuevo: [],
    pedidosNuevoDetalle: [],

    dominiosComprados: {
      idSolicitudAprobacionDominioDetalle: []
    },

  }),
  created() {
    this.informacionCompra = {};
  },
  mounted() {
    this.obtener();
    this.getPolices();
    this.cybs_dfprofiler();
    let datastorage = localStorage.getItem("localdomain");
    if (datastorage) {
      this.disponibilidadDominios = JSON.parse(datastorage);
      this.detallePedidos = JSON.parse(datastorage);
      console.log("get: ", this.detallePedidos);
      this.stepOneDisable = false;
    } else this.disponibilidadDominios = [];

    this.dominiosList = JSON.parse(localStorage.getItem("dominiosList"));
    if (!this.dominiosList) this.dominiosList = [];

    this.getPais();

    let infocomprastorage = localStorage.getItem(btoa("informacionCompra"));

    if (infocomprastorage) {
      this.informacionCompra = JSON.parse(atob(infocomprastorage));
    } else this.informacionCompra = {};
    this.getExpYear();
    if (window.localStorage.getItem("idPedido")) {
      this.codigoReferencia = localStorage.getItem("codigoReferencia");
      this.codigoResultado = localStorage.getItem("codigoResultado");
      this.e1 = 3;
    }
  },
  watch: {
    getDominiosList() {
      let datastorage = localStorage.getItem("localdomain");

      if (datastorage) {
        this.disponibilidadDominios = JSON.parse(datastorage);
        this.detallePedidos = JSON.parse(datastorage);

        

        
      } else this.disponibilidadDominios = [];
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    isStepOneValid() {
      if (this.detallePedidos === null) {
        this.stepOneDisable = true;
      }
      this.stepOneDisable = false;
    },
  },
  methods: {
    obtener(){
      let obtener = localStorage.getItem('localdomain');
      this.pedidosNuevo = JSON.parse(obtener);
      console.log("entro aca2 : ", this.pedidosNuevo);

      for (let index = 0; index < this.pedidosNuevo.length; index++) {
        const element = this.pedidosNuevo[index];
        
        this.pedidosNuevoDetalle.push({
          idZona: element.idZona,
          idNivel: element.idNivel,
          dominio: element.dominio,
          precioReal: element.precioReal,
          moneda: element.moneda,
          idDescuento: element.idDescuento,
          montoDescuento: element.montoDescuento
        });
        this.dominiosComprados.idSolicitudAprobacionDominioDetalle.push(
          element.idSolicitudAprobacionDominioDetalle
        );
      }

      console.log("otro: ", this.pedidosNuevoDetalle);

      
      console.log("pedido nuevo: ", this.dominiosComprados.idSolicitudAprobacionDominioDetalle);

    },
    toCompraDominio: function() {
      window.location = "/panel/dominios";
    },
    getPolices: async function() {
      await this.$http
        .get(this.$keys("TERMINOS_CONDICIONES"))
        .then((response) => {
          if (response.status === 200) {
            this.parrafo = response.data;
          }
        })
        .catch();
    },
    toasterMessage: function(tipo) {
      let message = "";
      let type = "";
      switch (tipo) {
        case 1:
          message = "correo reenviado con exito";
          type = "success";
          break;
        case 2:
          message = "dominio eliminado";
          type = "info";
          break;
      }
      Vue.$toast.open({
        message: message,
        type: type,
      });
    },
    toasterMessageResponse: function(msg) {
      Vue.$toast.open({
        message: msg,
        type: "error",
      });
    },
    addToList(newDomain) {
      console.log(newDomain);
      let datastorage = localStorage.getItem("localdomain");

      if (datastorage) {
        this.disponibilidadDominios = JSON.parse(datastorage);
        this.detallePedidos = JSON.parse(datastorage);
        console.log("get: ",this.detallePedidos);
        this.stepOneDisable = false;
      } else this.disponibilidadDominios = [];
    },
    reenviarcorreo: async function() {
      var token = localStorage.getItem(atob("usr"));
      let decodeToken = jwt_decode(token);
      let sendData = {
        intento: 1,
        correoCliente: decodeToken.email,
        codigoReferencia: window.localStorage.getItem("codigoReferencia"),
        codigoResultado: window.localStorage.getItem("codigoResultado"),
      };
      await this.$http
        .post(this.$keys("REENVIARCORREO"), sendData)
        .then((response) => {
          if (response.status === 200) {
            this.toasterMessage(1);
          }
        })
        .catch((error) => {
          this.flashMessage.show({
            status: "error",
            title: "Mi aplicación",
            message: "Los datos no son válidos" + error,
          });
        });
    },
    removeDomain: function(value) {

      // const index = this.detallePedidos.findIndex((x) => x.dominio === value);
      // this.detallePedidos.splice(index, 1);
      // localStorage.removeItem("localdomain", index);
      // localStorage.setItem("localdomain", JSON.stringify(this.detallePedidos));

      console.log("borrar: ", this.detallePedidos);
      const index = this.detallePedidos.findIndex((x) => x.dominio === value);
      this.detallePedidos.splice(index, 1);
      this.pedidosNuevoDetalle.splice(index,1);
      this.dominiosComprados.idSolicitudAprobacionDominioDetalle.splice(index,1);
      localStorage.removeItem("localdomain");
      localStorage.setItem(
        "localdomain",
        JSON.stringify(this.detallePedidos)
      );
      console.log("pedidosNuevoDetalle: ", this.pedidosNuevoDetalle);
      // if (this.detallePedidos.length === 0) this.stepOneDisable = true;
      // localStorage.removeItem("localdomain");
      // this.toasterMessage(2);
      // if (this.detallePedidos.length === 0) this.stepOneDisable = true;
      // localStorage.removeItem("localdomain");
      // this.toasterMessage(2);
    },
    proceder: async function() {
      this.dialog2 = false;
      this.loading = true;
      var token = localStorage.getItem(atob("usr"));
      let decodeToken = jwt_decode(token);
      

      let datastorage = localStorage.getItem("localdomain");
      if (datastorage) {
        this.detallePedidos = JSON.parse(datastorage);
      }

      for (var i = 0; i < this.detallePedidos.length; i++) {
        this.detallePedidos[i].dominio = this.detallePedidos[
          i
        ].dominio.substring(0, this.detallePedidos[i].dominio.indexOf("."));
      }

      this.Tarjeta.nombre =
        this.informacionCompra.nombres + " " + this.informacionCompra.apellidos;
      this.Tarjeta.Numeracion = btoa(this.tarjetaNumeracion);
      this.Tarjeta.mesExpira = btoa(this.tarjetaMesExpira);
      this.Tarjeta.anioExpira = btoa(this.tarjetaAnioExpira);
      this.Tarjeta.codigo = btoa(this.tarjetaCodigo);
      this.Tarjeta.nombre = btoa(this.Tarjeta.nombre);

      for (let indexPedido = 0; indexPedido < this.detallePedidos.length; indexPedido++) {
        const element = this.detallePedidos[indexPedido];

        console.log("yooo: ",element);
        
      }
      let dominiosComprados = {
        idSolicitudAprobacionDominioDetalle: this.dominiosComprados.idSolicitudAprobacionDominioDetalle
      };
      let pedido = {
        idCliente: decodeToken.IdCliente,
        email: decodeToken.email,
        detallePedidos: this.pedidosNuevoDetalle,
      };

      let sendData = {
        fingerPrint: "" + this.fingerPrint,
        direccionIP: this.direccionIP.ip,
        pedido: pedido,
        dominiosComprados: dominiosComprados,
        Tarjeta: this.Tarjeta,
        informacionCompra: this.informacionCompra,
      };
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .post(this.$keys("PAGOS"), sendData)
        .then((response) => {
          if (response.status === 201) {
            this.e1 = 3;
            window.localStorage.removeItem("localdomain");
            this.codigoReferencia = response.data.pagoResult.codigoReferencia;
            this.codigoResultado = response.data.pagoResult.codigoResultado;
            this.idPedido = response.data.pagoResult.idPedido;
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.toasterMessageResponse(
              "Revise la informacion ingresada antes de continuar"
            );
          } else this.toasterMessageResponse(error.response.data.mensaje);
        });

      this.loading = false;
    },
    getPais: async function() {
      await this.$http.get(this.$keys("PAIS")).then((resp) => {
        if (resp.status == 200) {
          this.paisList = resp.data;
        }
      });
    },
    hackIP: async function() {
      const response = await fetch("https://api.ipify.org?format=json");
      this.direccionIP = await response.json();
    },
    getExpYear: function() {
      let today = new Date();
      let year = today.getFullYear();
      for (var i = year; i <= year + 5; i++) {
        let fecha = { description: "" + i, value: "" + i };
        this.expireYear.push(fecha);
      }
    },
    pasoDos: async function() {
      await this.hackIP();
      //   localStorage.removeItem(btoa('informacionCompra'));
      localStorage.setItem(
        btoa("informacionCompra"),
        btoa(JSON.stringify(this.informacionCompra))
      );
    },
    cybs_dfprofiler: function() {
      // let token = window.localStorage.getItem(window.atob( 'usr' ));
      let merchantID = "tc_ni_001412311";
      let environment = "cybersource.environment.sandbox";
      let org_id = "";
      if (environment.toLowerCase() == "live") {
        org_id = "k8vif92e";
      } else {
        org_id = "1snn5n9w";
      }
      var sessionID = new Date().getTime();
      //One-Pixel Image Code
      var paragraphTM = document.createElement("p");
      let str = "";
      str =
        "background:url(https://h.online-metrix.net/fp/clear.png?org_id=" +
        org_id +
        "&session_id=" +
        merchantID +
        sessionID +
        "&m=1)";
      paragraphTM.style.cssText = str;
      document.body.appendChild(paragraphTM);
      var img = document.createElement("img");
      str =
        "https://h.online-metrix.net/fp/clear.png?org_id=" +
        org_id +
        "&session_id=" +
        merchantID +
        sessionID +
        "&m=2";
      img.src = str;
      img.alt = "";
      document.body.appendChild(img);
      //Flash Code
      var objectTM = document.createElement("object");
      objectTM.data =
        "https://h.online-metrix.net/fp/fp.swf?org_id=" +
        org_id +
        "&session_id=" +
        merchantID +
        sessionID;
      objectTM.type = "application/x-shockwave-flash";
      objectTM.width = "1";
      objectTM.height = "1";
      objectTM.id = "thm_fp";
      var param = document.createElement("param");
      param.name = "movie";
      param.value =
        "https://h.online-metrix.net/fp/fp.swf?org_id=" +
        org_id +
        "&session_id=" +
        merchantID +
        sessionID;
      objectTM.appendChild(param);
      document.body.appendChild(objectTM);
      //JavaScript Code
      var tmscript = document.createElement("script");
      tmscript.src =
        "https://h.online-metrix.net/fp/tags.js?org_id=" +
        org_id +
        "&session_id=" +
        merchantID +
        sessionID;
      tmscript.type = "text/javascript";
      document.body.appendChild(tmscript);
      this.fingerPrint = sessionID;
    },
    scrollTop() {
      setTimeout(() => {
        window.scrollTo({
          top: 380,
        });
      }, 200);
    },
    validate(n) {
      this.steps[n].valid = false;
      let v = this.$refs.stepForm[n].validate();
      if (v) {
        this.steps[n].valid = true;
        // continue to next
        this.curr = n + 2;
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>
<style>
.large {
  width: 790px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 0;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-top: 75px;
}
.sticky-bottom {
  bottom: 0;
  position: sticky;
  padding-bottom: 75px;
}
@media (max-width: 725px) {
  .compra-dominio {
    height: 10vh !important;
  }
}

.color-texto {
  color: #616161;
  font-size: 28px !important;
}

.subtitle {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 29px;
  color: #616161;
}

.title-dominio {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 30px !important;
  line-height: 30px !important;
  color: #616161 !important;
}

.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  color: rgba(0, 0, 0, 0.4) !important;
}

.text-dominio {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 40px !important;
}

.text-precio {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 28px !important;
  line-height: 18px !important;
  color: #616161 !important;
}

.text-precio2 {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 22px !important;
  line-height: 25px !important;
  color: #abaaaa !important;
}

.text-precio3 {
  text-align: right;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 22px !important;
  color: #abaaaa !important;
}
.total {
  font-weight: 600;
  color: #000 !important;
  font-size: 20px !important;
}
.text-negro {
  color: #000 !important;
  font-size: 20px !important;
}

.decoracion {
  text-decoration: none;
}

@media (max-width: 725px) {
  .text-precio {
    text-align: center !important;
  }
  #card-mobile {
    display: block;
  }
  #card-desk {
    margin-left: 0px !important;
    display: none;
  }
  .text-precio {
    font-size: 22px !important;
  }
  .large {
    width: 230px !important;
  }
  .text-precio2 {
    font-weight: 500 !important;
    line-height: 17px !important;
    font-size: 16px !important;
    text-align: left;
  }
  #mobileprecio {
    font-weight: 500 !important;
    font-size: 16px !important;
    text-align: left;
    margin-top: -10px;
  }
  .iconMobile {
    text-align: left !important;
  }
  .tiposTarjeta {
    margin-top: 20px !important;
    position: absolute !important;
    margin-bottom: 0px !important;
  }
}
@media (min-width: 725px) {
  #card-mobile {
    display: none;
  }
}
.mdi-menu-down::before {
  content: "\F035D";
  color: #abaaaa !important;
}

.text-terminos {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #abaaaa !important;
}

.terminos-bold {
  font-size: 14px !important;
  color: #17a2b8 !important;
  line-height: 17px !important;
  font-weight: 500;
}

.v-stepper__header {
  box-shadow: none !important;
}

.v-stepper {
  box-shadow: none !important;
}

.v-stepper__step .v-icon {
  font-size: 28px !important;
}

.v-icon {
  size: 20px !important;
}

.custom-transform-class {
  text-transform: uppercase;
}

.custom-transform-class {
  text-transform: uppercase;
}

.v-stepper__step__step {
  height: 50px !important;
  width: 50px !important;
  min-width: 24px;
  font-size: 1.5rem;
}
.v-dialog > .v-card > .v-card__title {
  color: #fff !important;
}

.v-application .primary--text {
  color: #abaaaa !important;
  caret-color: #ffffff !important;
}
.v-list-item__content {
  padding: 0 !important;
}
.color-stepper {
  color: #a8a7a7 !important;
  border: none;
  text-shadow: none !important;
}
.v-application p {
  margin-bottom: 2px !important;
}
.v-application p {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-dialog > .v-card > .v-card__title {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-application .display-3 {
  font-size: 1rem !important;
}
</style>
